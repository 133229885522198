/* eslint-disable no-restricted-globals */
import { useEffect, useRef, useState } from 'react';

import { ASX_DEBORAH_LANDING_PAGE } from 'src/constants';
import { THEME } from 'src/enums/theme';

type RouteThemeMap = {
  [key: string]: {
    value: THEME;
    // Optional weblab to be evaluated by the calling component before applying theme.
    weblab?: string;
  };
};

export const DEFAULT_THEME = { value: THEME.LIGHT };

// Define the default route colors
const THEME_BY_ROUTE: RouteThemeMap = {
  '/': DEFAULT_THEME,
  '/credits': { value: THEME.DARK, weblab: ASX_DEBORAH_LANDING_PAGE },
};

/**
 * Returns theme config data to be used for the current page route.
 * Weblabs should be evaluated by the calling component before applying theme.
 * @returns Theme config data
 */
export const useThemeConfig = () => {
  const [themeConfig, setThemeConfig] = useState<{ value: THEME; weblab?: string }>(DEFAULT_THEME);

  let previousUrl = '';

  // Utilize a MutationObserver against the root document to listen for url changes
  // https://tiny.amazon.com/1s8r9h47/url-listening
  // https://tiny.amazon.com/w7rjjvqw/mutation-observers
  const observer = useRef(
    new MutationObserver(() => {
      if (location.href !== previousUrl) {
        previousUrl = location.href;
        const path = location.pathname.replace(/\/+$/, '');
        setThemeConfig(THEME_BY_ROUTE[path] || DEFAULT_THEME);
      }
    })
  );

  useEffect(() => {
    const observerConfig = { subtree: true, childList: true };
    observer.current.observe(document, observerConfig);
  }, [observer]);

  return { themeConfig, observer: observer.current };
};
