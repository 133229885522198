import classNames from 'classnames';
import { FunctionComponent } from 'react';

import { useWeblab } from '@amzn/sustainability-central-shared-react';

import { MenuItem } from 'src/components/common/menu-item/MenuItem';
import { useFooterNavItems } from 'src/components/footer/hooks/use-footer-nav-items';
import { NAV_MENU_IDS, NAV_PLACEMENT } from 'src/enums/navigation';
import { DEFAULT_THEME, useThemeConfig } from 'src/hooks/use-theme-config';

import styles from './Footer.module.scss';

export const Footer: FunctionComponent = () => {
  const { navItems } = useFooterNavItems();
  const { themeConfig } = useThemeConfig();
  const isThemeWeblabEnabled = useWeblab(themeConfig.weblab ?? '') === 'T1';

  // eslint-disable-next-line no-nested-ternary
  const themeValue = themeConfig.weblab
    ? isThemeWeblabEnabled
      ? themeConfig.value
      : DEFAULT_THEME.value
    : themeConfig.value;

  return (
    <footer className={classNames(styles.footer, styles[themeValue])}>
      <nav aria-label="secondary" className={styles.navigation}>
        <ul className={styles.listContainer}>
          {navItems.map((item) => (
            <MenuItem
              isAlignedRight={item.id === NAV_MENU_IDS.PRIVACY_NOTE}
              key={item.id}
              navItem={item}
              placement={NAV_PLACEMENT.FOOTER}
              theme={themeValue}
            />
          ))}
        </ul>
      </nav>
    </footer>
  );
};
